html {
    height: 100%;
    body {
        height: 100%;
        div#root {
            height: 100%;

            div.choice {
                height: 100%;
            }
        }
    }
}


.TrainerAppComponent {
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;

    .questionAnswer {
       margin-top:10%;
       flex-grow: 1;
    }
    .menu {
        padding-top:2%;
    }

    .linkToRule {
        padding-top:20px;
    }

    .foundError {
        padding-bottom: 1%;
    }

    .darkMode {
        padding-bottom: 2%;
    }


    .FilterEditorComponentContainer {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }


    button.next {
        background-color: darkgreen; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
    }

    button.next:focus {
        box-shadow: 0 0 5pt 4pt #719ECE;    
      }

      .greenPct {
          color: darkgreen;
      }
    
    .yellowPct {
        color:darkorange;
    }
    
    .redPct {
        color: red;
    }

    .resetStats{
        padding:20px;
        a {
            color: red;
        }
    }

    a {
        text-decoration: none;
        border-bottom: dotted thin;
    }

    .linkToRule, a {
        color: grey;
    }
}



.dark {
    background-color: #2d2a2d;
    color: #b2b4b5;

    .greenPct {
        color: lightgreen;
    }
  
  .yellowPct {
      color:darkorange;
  }
  
  .redPct {
      color: lightcoral;
  }
}
.BodyQuestionComponent {
    // width: fit-content;
    line-height: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;

    button {
      margin-top: 10px;
      font-size: 14px;
    }
    
    select {
      font-size: 18px;
    }

    select.success {
        background: green;
        color: white;
    }
    
    span.hint {
      color: silver;
    }

    select.error {
        background: red;
        color: white;
    }

    sup.success {
      color: green;
    }

    sup.error {
        color: red;
    }

    select:focus, button:focus {
      box-shadow: 0 0 3pt 2pt red;    
    }

    select {
      box-shadow: 0 0 3pt 2pt transparent;
    }

     select {
       margin: 0px 2pt;
     }

    button {
      background-color: darkslategray; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
    }

    i {
      font-weight: bold;
    }
    
    
}



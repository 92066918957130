.TopicsTreeComponent {
    font-size:95%;
    ul {
        white-space: nowrap;
        text-align: left;
        line-height: 20px;
        padding-inline-start: 10px;
        list-style-type: none;
    }
    a.selectedNode {
        color: red;
        font-weight: bold;
    }
    .error {
        color: red;
    }
    .ruleHl {
        background-color: rgba(204, 255, 204); /* lightgreen with 70% opacity */
      }
      
      .debugHl {
        background-color: rgba(255, 255, 105); /* yellow with 70% opacity */
      }
      
      .nrHl {
        background-color: rgba(255, 185, 0); /* orange with 70% opacity */
      }
    .todoHl {
        background-color: red;
        color: white !important;
    }
    
    strong {
        background-color: red;
        color: white;
    }
}
.FilterEditorComponent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    input[type="checkbox"][readonly]:checked {
        background-color: silver;
      }

      .debug {
        background-color: yellow;
      }

    td {
        vertical-align: top;
    }

    .highlighted {
        background-color: #efe;
    }

    
    div.header {
        display: flex;

        .left {
            flex-grow: 1;
        }
        .right {
            flex-grow: 1;
            visibility:hidden;
        }
    }

    div.tableContainer {
        margin: 0px auto;
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        div.mainFilterTableContainer {
            flex-grow: 1;
            overflow-y: scroll;
            height: 300px;

        
        }
    }

    div.tableHeader {
        display: flex;
        flex-direction: row;
    }


    .sorting {
        text-align: right;
        color: grey;
    }

    .topicRow > td {
            border-bottom: 1px solid silver;
        }

    .topicRow:last-child > td {
        border-bottom: 0px;
    }

    .back {
        text-align: left;
        padding:10px 20px;
        a {
            color: grey;
        }
    }
    

    a.expand {
        color: grey;
    }
        

    .firstCol {
        text-align: left;
        flex-grow: 1;
    }


    table.mainFilterTable {        
        text-align:left;

        table {
            background-color: #eee;
        }
    }
}


.dark {
    .FilterEditorComponent {
        table.mainFilterTable {        
         
            table {
                background-color: #333237;
            }
        }

        .highlighted {
            background-color: #535559;
        }
    }   

    .left {
        img {
            filter: invert(1);
        }
    }
    
}
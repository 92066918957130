.BrowserWarning {
    border: 1px solid #000;
    background-color: red;
    color: white;
    padding: 5px;
    a {
        color: yellow !important;
    }
    .details {
        margin-top:15px;
    }
}
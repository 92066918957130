.FilterLinkComponent {
    .active {
        visibility: hidden;
    }

    color:#38474D;
    
}

.dark .FilterLinkComponent {
    color: #e5b07e;
}
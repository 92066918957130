body, html {
  height: 100%;
  margin: 0;
}

.Browser {
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap:  nowrap;
  width: 100%;
  height:100%;

  .col {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .cell {
    border: 1px solid #ccc;
    box-sizing: border-box;
    padding: 0px;
  }

  .scrollable {
    overflow: auto; /* Enable cell-level scrollbar */
  }

  .colContent {
    flex: 1;
  }

  textarea {
    width: 300px;
    height: 200px;
  }

  .topics {
    background-color: #fafafa;
    overflow-y: scroll;
  }
  .content {
    padding: 20px;
    flex:1;
  }
  hr {
    width: 100%;
    visibility: hidden;
    height:0;
    padding: 0;
    margin:0;
  }

  .Browser-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .Browser-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .Browser-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .Browser-link {
    color: #61dafb;
  }
  
  @keyframes Browser-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  div.error {
    color: #a00;
    border: 1px solid #a00;
  }
  
  span.error {
    color: #a00;
    border: 1px solid #a00;
  }
  
  
}

